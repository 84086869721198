.en * {
  direction: ltr !important;
}
.en .Amer-logo {
  left: 7%;
  right: auto;
}
.en .add-icon {
  left: 15px;
  right: auto;
}
.en .pdf-icon {
  right: 184px;
  left: auto;
}
.en .pdf-icon2 {
  right: 114px;
  left: auto;
}
.en .pdf-icon3 {
  right: 154px;
  left: auto;
}
.en .close_circle_fill {
  right: 0;
  left: auto;
}
.en .user-dots {
  right: 24px;
  left: auto;
}
.en .home-banner {
  background-image: url("/public/home-images2.jpg");
}
@media (min-width: 1024px) {
  .en .op-btn {
    left: 40px;
  }
}
.en .op-btn {
  right: 4%;
  left: auto;
}

.en .que-icon {
  right: -30px;
  left: auto;
}
.en .whats-icon {
  right: 16px;
  left: auto;
}
.en .sidebar-toggle1 {
  right: -20px;
  left: auto;
  rotate: 180deg;
  top: 50px;
}
@media (min-width: 1280px) {
  .en .sidebar-toggle1 {
    left: 209px;
    right: auto;
  }
}
.en .sidebar-toggle2 {
  left: -27px;
  right: auto;
  rotate: -180deg;
  top: 64px;
}
.en .Edit {
  right: 24px;
  left: auto;
}
.en .swiper-pagination-bullets {
  left: 0 !important;
  right: auto !important;
}
.actions .ant-select {
  width: 95px !important;
}
.btn-actions button:hover {
  color: #00e6d7 !important;
  border-color: #2cfff1 !important;
}
.en .rev-rotate {
  rotate: -180deg;
}
.ar .rev-rotate {
  margin-top: 3.5px;
}
.ar .rotate-img {
  rotate: -180deg;
  margin-top: 3px;
}
.en .flex-reverse {
  flex-direction: row-reverse !important;
}
